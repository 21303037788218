import { WordsType } from '../keys';

const wordsKeys: WordsType = {
  accept: 'Accept',
  accordingTo: 'according to',
  account: 'Account',
  accountManager: 'Account manager',
  active: 'Active',
  activity: 'Activity',
  actions: 'Actions',
  add: 'Add',
  addItem: 'Add item',
  addMore: 'Add more',
  addReaction: 'Add reaction',
  addService: 'Add service',
  addToFavorites: 'Add to favorites',
  addUser: 'Add user',
  addYourCommentOptional: 'Add your comment (optional)',
  adorable: 'Adorable',
  all: 'All',
  allActivity: 'All activity',
  allAttachments: 'All attachments',
  attachments: 'Attachments',
  allocate: 'Allocate',
  allocateFunds: 'Allocate funds',
  allPages: 'All pages',
  allStatuses: 'All statuses',
  allTime: 'All time',
  allUsers: 'All users',
  already_selected: 'Already selected',
  amount: 'Amount',
  amountDue: 'Amount due',
  and: 'and',
  any: 'Any',
  anyType: 'Any type',
  applicationLanguage: 'Application language',
  apply: 'Apply',
  approve: 'Approve',
  artists: 'Artists',
  archivizerTeam: 'Archivizer team',
  as: 'as',
  assignAsCreator: 'Assign as creator',
  assignAsOwner: 'Assign as owner',
  attach: 'Attach',
  attachImage: 'Attach image',
  aud: 'AUD',
  autoAllocate: 'Auto allocate',
  author: 'Author',
  aws: 'AWS',
  back: 'Back',
  backToProject: 'Back to project',
  backtrace: 'Backtrace',
  badDebt: 'Bad debt',
  dataPrivacyPolicy: 'Data Privacy Policy',
  block: 'Block',
  blocked: 'Blocked',
  brand: 'Brand',
  brief: 'Brief',
  browse: 'Browse',
  budget: 'Budget',
  by: 'by',
  cancel: 'Cancel',
  canceled: 'Canceled',
  caution: 'Caution!',
  celebrate: 'Celebrate',
  change: 'Change',
  changeCurrency: 'Change currency',
  changeOwner: 'Change owner',
  changeUnits: 'Change units',
  clap: 'Clap',
  clear: 'Clear',
  clearAll: 'Clear all',
  clearFilter: 'Clear filter',
  clearFilters: 'Clear filters',
  clearSelection: 'Clear selection',
  clickToCopy: 'Click to copy',
  client: 'Client',
  clientDebt: 'Client debt',
  clientManager: 'Client manager',
  clients: 'Clients',
  close: 'Close',
  colors: 'Colors',
  colorScheme: 'Color scheme',
  colorSchemeDark: 'Dark',
  colorSchemeLight: 'Light',
  comment: 'Comment',
  company: 'Company',
  confirm: 'Confirm',
  contactInfo: 'Contact info',
  contactSupport: 'Contact support',
  continue: 'Continue',
  control: 'Control',
  copyId: 'Copy ID',
  copyLink: 'Copy link',
  copyUuid: 'Copy UUID',
  cost: 'Cost',
  coefficient: 'K',
  create: 'Create',
  createNewFilter: 'Create new filter',
  criticalRequirements: 'Critical requirements',
  current: 'Current',
  date: 'Date',
  dateAndTimeFormat: 'Date and time format',
  dateOfBirth: 'Date of birth',
  day: 'Day',
  debt: 'Debt',
  default: 'Default',
  delete_filter: 'Delete filter',
  delete: 'Delete',
  deselect: 'Deselect',
  denyAccess: 'Deny access',
  description: 'Description',
  descriptionOptional: 'Description (optional)',
  digitalOcean: 'Digital Ocean',
  disable: 'Disable',
  dislike: 'Dislike',
  download: 'Download',
  downloadAll: 'Download all',
  downloadAllUpperCase: 'DOWNLOAD ALL',
  downloadTaskResult: 'Download task result',
  dpi: 'dpi',
  draft: 'Draft',
  draftBudget: 'Draft budget',
  drawings: 'Drawings',
  dueDate: 'Due date',
  duplicate: 'Duplicate',
  edit: 'Edit',
  elasticSearch: 'Elastic search',
  emailAddress: 'Email address',
  emailChanged: 'Email changed',
  enable: 'Enable',
  endUserLicenseAgreementAndTermsOfService:
    'End User License Agreement and Terms of Service',
  english: 'English',
  enterMessageId: 'Enter message ID',
  enterMessageIds: 'Enter message IDs',
  error: 'Error',
  errorMessage: 'Error message',
  eur: 'EUR',
  expand_view: 'Expand view',
  expenses: 'Expenses',
  export: 'Export',
  favorite: 'Favorite',
  files: 'Files',
  filter: 'Filter',
  filterFavorite: 'Filter favorite',
  filters: 'Filters',
  fin: 'Fin',
  finance: 'Finance',
  fire: 'Fire',
  flagged: 'Flagged',
  for: 'for',
  forward: 'Forward',
  from: 'From',
  fullyPaid: 'Fully paid',
  gbp: 'GBP',
  gpt: 'GPT',
  general: 'General',
  generate: 'Generate',
  goBack: 'Go back',
  groupBy: 'Group by',
  gridView: 'Grid view',
  helpCenter: 'Help Center',
  hidden: 'Hidden',
  hiddenLowerCase: 'hidden',
  hide: 'Hide',
  hideDimensions: 'Hide dimensions',
  hideRuler: 'Hide ruler',
  hideList: 'Hide list',
  high: 'High',
  home: 'Home',
  homepage: 'Go to the home page',
  images: 'Images',
  imageScale: 'Image scale',
  import: 'Import',
  in: 'in',
  include: 'Include',
  included: 'Included',
  incomeStatement: 'Income statement',
  info: 'Info',
  invisibleForClient: 'Invisible for client',
  invisibleForWorker: 'Invisible for worker',
  invisible: 'Invisible',
  invite_user: 'Invite user',
  itc: 'ITC',
  its: "It's",
  joined: 'Joined',
  languagesSpoken: 'Languages spoken',
  lastMessage: 'Last message',
  lastMonth: 'Last month',
  lastWeek: 'Last week',
  lastYear: 'Last year',
  lastSixDays: 'Last 6 days',
  lastSixWeeks: 'Last 6 weeks',
  lastSixMonths: 'Last 6 months',
  lastSixYears: 'Last 6 years',
  latest: 'Latest',
  laughing: 'Laughing',
  learnMore: 'Learn more',
  leave: 'Leave',
  leaveMessage: 'Leave Message',
  lessMonth: '< 1 month',
  lessTwoMonth: '< 2 months',
  lessTwoWeek: '< 2 weeks',
  lessWeek: '< 1 week',
  letters: { h: 'h', m: 'm' },
  license: 'License',
  like: 'Like',
  link: 'Link',
  linkCopied: 'Link copied',
  listView: 'List view',
  loadMore: 'Load More',
  location: 'Location',
  log_out: 'Log out',
  login_page: 'Go to the login page',
  love: 'Love',
  low: 'Low',
  mad: 'Mad',
  makeInvisible: 'Make invisible',
  makeVisible: 'Make visible',
  makeDefault: 'Make default',
  media: 'Media',
  members: 'Members',
  membersGenitiveLowerCase: 'members',
  mergeSimilar: 'Merge similar',
  model: 'Model',
  month: 'Month',
  more: 'More',
  moreTwoMonth: '> 2 months',
  mostLiked: 'Most liked',
  mostUsed: 'Most used',
  move: 'Move',
  myLibrary: 'My library',
  na: 'n/a',
  name: 'Name',
  nameEn: 'Name (En)',
  nda: 'NDA',
  ndaFile: 'NDA file',
  needHelp: 'Need help?',
  next: 'Next',
  new: 'New',
  newest: 'Newest',
  net: 'Net',
  no: 'No',
  noGrouping: 'No grouping',
  noResultsYet: 'No results yet',
  noSearchResults: 'No search results',
  notAsked: 'Not asked',
  notification: 'Notification',
  of: 'of',
  ok: 'Ok',
  on: 'on',
  onboardingTour: 'Onboarding tour',
  oneDay: '1 day',
  oneMonth: '1 month',
  oneWeek: '1 week',
  open_in_new_tab: 'Open in new tab',
  open: 'Open',
  openOptions: 'Open options',
  or: 'Or',
  overdue: 'Overdue',
  owner: 'Owner',
  paid: 'Paid',
  passwordChanged: 'Password changed',
  pay: 'Pay',
  payByCard: 'Pay by card',
  payOnline: 'Pay online',
  payer: 'Payer',
  paymentMethod: 'Payment Method',
  payments: 'Payments',
  paymentTerms: 'Payment Terms',
  paymentTermsAndRefundPolicy: 'Payment terms and refund policy',
  pendingPayments: 'Pending payments',
  phoneNumber: 'Phone number',
  pinned: 'Pinned',
  policies: 'Policies',
  postMessage: 'Post message',
  premiumContent: 'Premium content',
  previewModel: 'Preview model',
  price: 'Price',
  privacy: 'Privacy',
  privacyPolicy: 'Privacy policy',
  proceed: 'Proceed',
  processing: 'Processing...',
  profile: 'Profile',
  proforma: 'Proforma',
  proformaOld: 'Proforma old',
  progress: 'Progress',
  publish: 'Publish',
  published: 'Published',
  px: 'px',
  quantity: 'Quantity',
  receipt: 'Receipt',
  receiveNotifications: 'Receive notifications',
  receiver: 'Receiver',
  recentlySelected: 'Recently selected',
  references: 'References',
  refund: 'Refund',
  refunds: 'Refunds',
  remove: 'Remove',
  removeFromFavorites: 'Remove from favorites',
  requirements: 'Requirements',
  reset_password: 'Reset password',
  reset: 'Reset',
  resolve: 'Resolve',
  resolved: 'Resolved',
  results: 'results',
  rub: 'RUB',
  russian: 'Russian',
  sad: 'Sad',
  save: 'Save',
  saveChanges: 'Save Changes',
  savedFilters: 'Saved filters',
  saveFilter: 'Save filter',
  scopeOfWork: 'Scope of work',
  score: 'Score',
  search_user_by_name: 'Search user by name',
  search: 'Search',
  searching: 'Searching...',
  searchResults: 'Search results',
  secondsGenitive: 'seconds',
  select: 'Select',
  selectAll: 'Select all',
  selected: 'Selected',
  selectToAddToTheProject: 'Select to add to the project',
  send: 'Send',
  sendTo: 'Send to',
  sentTo: 'Sent to',
  sender: 'Sender',
  sendInstructions: 'Send instructions',
  sendToAllTasks: 'Send to all tasks',
  sendToEmail: 'Send to email',
  sendViaEmail: 'Send via email',
  sent: 'Sent',
  serviceAgreement: 'Service agreement',
  serviceName: 'Service name',
  setAllVisible: 'Set all visible',
  setAllHidden: 'Set all hidden',
  setCustomDate: 'Set custom date',
  setDueDate: 'Set due date',
  settings: 'Settings',
  show_n_selected_items: {
    show: 'Show',
    hide: 'Hide',
    selected_items: 'selected items'
  },
  show: 'Show',
  showAll: 'Show all',
  showDimensions: 'Show dimensions',
  showRuler: 'Show ruler',
  showFavoriteOnly: 'Show favorite only',
  showing: 'Showing',
  showLess: 'Show less',
  showList: 'Show list',
  showMore: 'Show more',
  showMyLibrary: 'Show my library',
  showTotals: 'Show totals',
  sign_out: 'Sign out',
  size: 'Size',
  sketches: 'Sketches',
  skip: 'Skip',
  skipStep: 'Skip step',
  sku: 'SKU',
  smartContract: 'Smart contract',
  sortAZ: 'Sort A-Z',
  sortZA: 'Sort Z-A',
  sortBy: 'Sort by',
  sortByMostLiked: 'Sort by: Most liked',
  sortByMostUsed: 'Sort by: Most used',
  sortByNewest: 'Sort by: Newest',
  starred: 'Starred',
  startDate: 'Start date',
  state: 'State',
  status: 'Status',
  statusColon: 'Status:',
  start: 'Start',
  stopAll: 'Stop all',
  submit: 'Submit',
  subscribed: 'Subscribed',
  success: 'Success',
  surnameEn: 'Surname (En)',
  surprised: 'Surprised',
  system: 'System',
  task: 'Task',
  tax: 'Tax',
  tbd: 'TBD',
  terms: 'Terms',
  termsOfService: 'Terms of Service',
  terrified: 'Terrified',
  testProjectInfo: 'Test project info',
  textHere: 'Text here',
  textures: 'Textures',
  tfe: 'TFE',
  thereAreNoMatchesForSelectedFilters:
    'There are no matches for selected filters',
  threeDs: '3Ds',
  threeDStock: '3D Stock',
  time: 'Time',
  timer: {
    days: 'days',
    hours: 'hours',
    minutes: 'minutes',
    seconds: 'seconds'
  },
  times: 'Times',
  timeZone: 'Time zone',
  to: 'To',
  today: 'Today',
  total: 'Total',
  totalDebt: 'Total debt',
  trackedTime: 'Tracked time',
  tryAgain: 'Try again',
  uah: 'UAH',
  ukraine: 'Ukrainian',
  unblock: 'Unblock',
  underpayment: 'Underpayment',
  units: {
    cm: 'cm',
    in: 'in'
  },
  untagged: 'Untagged',
  unsubscribed: 'Unsubscribed',
  unselectAllItems: 'Unselect all items',
  undefined: 'Undefined',
  update: 'Update',
  updated: 'Updated',
  updateExistingFilter: 'Update existing filter',
  updateImageVersions: 'Update image versions',
  upload: 'Upload',
  usd: 'USD',
  used: 'Used',
  user: 'User',
  vat: 'VAT',
  view: 'View',
  view3DModel: 'View 3D model',
  viewAccounts: 'View accounts',
  viewAll: 'View all',
  viewAllMembers: 'View all members',
  viewCR: 'View CR',
  viewProfile: 'View Profile',
  visibility: 'Visibility',
  visibilityForClient: 'Visibility for client',
  visibilityForWorker: 'Visibility for worker',
  visible: 'Visible',
  visibleForClient: 'Visible for client',
  void: 'Void',
  week: 'Week',
  welcomeToArchivizer: 'Welcome to ARCHIVIZER!',
  whiteboard: 'Whiteboard',
  withdraw: 'Withdraw',
  workers: 'Workers',
  watchGuide: 'Watch guide',
  workflowGuide: 'Workflow guide',
  writeHere: 'Write here',
  whiteboardNotifications: 'Whiteboard notifications',
  yes: 'Yes',
  yesterday: 'Yesterday'
};

export default wordsKeys;
