import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

interface SmartContractsKeyType {
  addIteration: LocalesKey;
  addShare: LocalesKey;
  av: LocalesKey;
  avGetsFixedShare: LocalesKey;
  avGetsRemainderAfterAllOtherParticipants: LocalesKey;
  avMinimumShare: LocalesKey;
  avShareScheme: LocalesKey;
  archivizer: LocalesKey;
  areYuSureYouWantToCancelTheSmartContract: LocalesKey;
  areYuSureYouWantToClearAllSmartContractShares: LocalesKey;
  areYuSureYouWantToDeleteTheSmartContractAutoCheckInRule: LocalesKey;
  areYuSureYouWantToDeleteTheCompanySmartContractTemplate: LocalesKey;
  amount: LocalesKey;
  allSmartContracts: LocalesKey;
  author: LocalesKey;
  autoPaymentSc: LocalesKey;
  bound: LocalesKey;
  basicSmartContract: LocalesKey;
  cancel: LocalesKey;
  canceled: LocalesKey;
  created: LocalesKey;
  createTemplate: LocalesKey;
  createSmartContractTemplate: LocalesKey;
  createRule: LocalesKey;
  createSmartContractAutoCheckInRule: LocalesKey;
  createCompanySmartContractTemplate: LocalesKey;
  closed: LocalesKey;
  clearAllShares: LocalesKey;
  changeShare: LocalesKey;
  checkIn: LocalesKey;
  checkOut: LocalesKey;
  checkTheAvailabilityOfAllSmartContractRules: LocalesKey;
  contractorRule: LocalesKey;
  delete: LocalesKey;
  deleteIteration: LocalesKey;
  dueDate: LocalesKey;
  draftSmartContract: LocalesKey;
  draftSmartContracts: LocalesKey;
  editSmartContractAutoCheckInRule: LocalesKey;
  editCompanySmartContractTemplate: LocalesKey;
  events: LocalesKey;
  fees: LocalesKey;
  forBasicWorkflow: LocalesKey;
  forPaymentsFromAv: LocalesKey;
  forPaymentsFromPfr: LocalesKey;
  fixed: LocalesKey;
  finalIteration: LocalesKey;
  finalIterationTotal: LocalesKey;
  id: LocalesKey;
  internalSmartContract: LocalesKey;
  internalSmartContractIsUsedForPaymentsFromPfrPfbdOrDirectlyFromArchivizer: LocalesKey;
  invoices: LocalesKey;
  iteration: LocalesKey;
  joinTheClients: LocalesKey;
  joinTheWorkers: LocalesKey;
  holdRate: LocalesKey;
  label: LocalesKey;
  name: LocalesKey;
  net: LocalesKey;
  netAmount: LocalesKey;
  noAvShareInSmartContract: LocalesKey;
  noParticipantsYet: LocalesKey;
  openSmartContract: LocalesKey;
  opened: LocalesKey;
  participants: LocalesKey;
  paid: LocalesKey;
  payer: LocalesKey;
  payFromAV: LocalesKey;
  payFromPFR: LocalesKey;
  payFromPFBD: LocalesKey;
  plural: LocalesKey;
  pfbd: LocalesKey;
  pfr: LocalesKey;
  project: LocalesKey;
  process: LocalesKey;
  postponed: LocalesKey;
  provisionForRevision: LocalesKey;
  provisionForBadDebt: LocalesKey;
  revenueSharing: LocalesKey;
  revision: LocalesKey;
  revisionSmartContract: LocalesKey;
  residual: LocalesKey;
  removeFromContract: LocalesKey;
  removeShare: LocalesKey;
  renew: LocalesKey;
  role: LocalesKey;
  singular: LocalesKey;
  scf: LocalesKey;
  scTat: LocalesKey;
  smartContractFee: LocalesKey;
  smartContractFees: LocalesKey;
  smartContractType: LocalesKey;
  smartContractTotal: LocalesKey;
  smartContractTemplates: LocalesKey;
  smartContractTemplate: LocalesKey;
  share: LocalesKey;
  status: LocalesKey;
  statuses: {
    open: LocalesKey;
    canceled: LocalesKey;
    draft: LocalesKey;
    done: LocalesKey;
  };
  statusLabels: {
    all: LocalesKey;
    open: LocalesKey;
    canceled: LocalesKey;
    closed: LocalesKey;
    draft: LocalesKey;
    done: LocalesKey;
  };
  task: LocalesKey;
  totalAmount: LocalesKey;
  totalShareExceeds100: LocalesKey;
  terms: LocalesKey;
  transactionFee: LocalesKey;
  trx: LocalesKey;
  value: LocalesKey;
  userId: LocalesKey;
  usersFromTheListBelowWillBeAutomaticallyCheckedIntoAllNewSmartContractsInWhichParticipate: LocalesKey;
  willBeAutomaticallyCheckedIntoAllNewSmartContractsInWhichParticipateUsersFromTheListBelow: LocalesKey;
  willBeAutomaticallyCheckedForAllNewSmartContractsCreatedForClientsFromTheListBelow: LocalesKey;
}

export const smartContractsKeys = keyPathMirror<SmartContractsKeyType, string>(
  {
    addIteration: null,
    addShare: null,
    av: null,
    avGetsFixedShare: null,
    avGetsRemainderAfterAllOtherParticipants: null,
    avMinimumShare: null,
    avShareScheme: null,
    archivizer: null,
    areYuSureYouWantToCancelTheSmartContract: null,
    areYuSureYouWantToClearAllSmartContractShares: null,
    areYuSureYouWantToDeleteTheSmartContractAutoCheckInRule: null,
    areYuSureYouWantToDeleteTheCompanySmartContractTemplate: null,
    amount: null,
    allSmartContracts: null,
    author: null,
    autoPaymentSc: null,
    bound: null,
    basicSmartContract: null,
    cancel: null,
    canceled: null,
    created: null,
    createTemplate: null,
    createSmartContractTemplate: null,
    createRule: null,
    createSmartContractAutoCheckInRule: null,
    createCompanySmartContractTemplate: null,
    closed: null,
    clearAllShares: null,
    changeShare: null,
    checkIn: null,
    checkOut: null,
    checkTheAvailabilityOfAllSmartContractRules: null,
    contractorRule: null,
    delete: null,
    deleteIteration: null,
    dueDate: null,
    draftSmartContract: null,
    draftSmartContracts: null,
    editSmartContractAutoCheckInRule: null,
    editCompanySmartContractTemplate: null,
    events: null,
    fees: null,
    forBasicWorkflow: null,
    forPaymentsFromAv: null,
    forPaymentsFromPfr: null,
    fixed: null,
    finalIteration: null,
    finalIterationTotal: null,
    id: null,
    internalSmartContract: null,
    internalSmartContractIsUsedForPaymentsFromPfrPfbdOrDirectlyFromArchivizer:
      null,
    invoices: null,
    iteration: null,
    joinTheClients: null,
    joinTheWorkers: null,
    holdRate: null,
    label: null,
    name: null,
    net: null,
    netAmount: null,
    noAvShareInSmartContract: null,
    noParticipantsYet: null,
    openSmartContract: null,
    opened: null,
    participants: null,
    paid: null,
    payer: null,
    payFromAV: null,
    payFromPFR: null,
    payFromPFBD: null,
    plural: null,
    pfbd: null,
    pfr: null,
    project: null,
    process: null,
    postponed: null,
    provisionForRevision: null,
    provisionForBadDebt: null,
    revenueSharing: null,
    revision: null,
    revisionSmartContract: null,
    residual: null,
    removeFromContract: null,
    removeShare: null,
    renew: null,
    role: null,
    scf: null,
    scTat: null,
    singular: null,
    smartContractFee: null,
    smartContractFees: null,
    smartContractType: null,
    smartContractTotal: null,
    smartContractTemplates: null,
    smartContractTemplate: null,
    status: null,
    statuses: {
      open: null,
      canceled: null,
      draft: null,
      done: null
    },
    statusLabels: {
      all: null,
      open: null,
      canceled: null,
      closed: null,
      draft: null,
      done: null
    },
    share: null,
    task: null,
    totalAmount: null,
    totalShareExceeds100: null,
    terms: null,
    transactionFee: null,
    trx: null,
    value: null,
    userId: null,
    usersFromTheListBelowWillBeAutomaticallyCheckedIntoAllNewSmartContractsInWhichParticipate:
      null,
    willBeAutomaticallyCheckedIntoAllNewSmartContractsInWhichParticipateUsersFromTheListBelow:
      null,
    willBeAutomaticallyCheckedForAllNewSmartContractsCreatedForClientsFromTheListBelow:
      null
  },
  'models.smartContracts.'
);
