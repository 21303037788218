import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from './localesKeysTypes';

export interface WordsType {
  accept: LocalesKey;
  accordingTo: LocalesKey;
  account: LocalesKey;
  accountManager: LocalesKey;
  active: LocalesKey;
  activity: LocalesKey;
  actions: LocalesKey;
  add: LocalesKey;
  addItem: LocalesKey;
  addMore: LocalesKey;
  addReaction: LocalesKey;
  addService: LocalesKey;
  addToFavorites: LocalesKey;
  addUser: LocalesKey;
  addYourCommentOptional: LocalesKey;
  adorable: LocalesKey;
  all: LocalesKey;
  allActivity: LocalesKey;
  allAttachments: LocalesKey;
  attachments: LocalesKey;
  allocate: LocalesKey;
  allocateFunds: LocalesKey;
  allPages: LocalesKey;
  allStatuses: LocalesKey;
  allTime: LocalesKey;
  allUsers: LocalesKey;
  already_selected: LocalesKey;
  amount: LocalesKey;
  amountDue: LocalesKey;
  and: LocalesKey;
  any: LocalesKey;
  anyType: LocalesKey;
  applicationLanguage: LocalesKey;
  apply: LocalesKey;
  approve: LocalesKey;
  artists: LocalesKey;
  archivizerTeam: LocalesKey;
  as: LocalesKey;
  assignAsCreator: LocalesKey;
  assignAsOwner: LocalesKey;
  attach: LocalesKey;
  attachImage: LocalesKey;
  aud: LocalesKey;
  autoAllocate: LocalesKey;
  author: LocalesKey;
  aws: LocalesKey;
  back: LocalesKey;
  backToProject: LocalesKey;
  backtrace: LocalesKey;
  badDebt: LocalesKey;
  dataPrivacyPolicy: LocalesKey;
  block: LocalesKey;
  blocked: LocalesKey;
  brand: LocalesKey;
  brief: LocalesKey;
  browse: LocalesKey;
  budget: LocalesKey;
  by: LocalesKey;
  cancel: LocalesKey;
  canceled: LocalesKey;
  caution: LocalesKey;
  celebrate: LocalesKey;
  change: LocalesKey;
  changeCurrency: LocalesKey;
  changeOwner: LocalesKey;
  changeUnits: LocalesKey;
  clap: LocalesKey;
  clear: LocalesKey;
  clearAll: LocalesKey;
  clearFilter: LocalesKey;
  clearFilters: LocalesKey;
  clearSelection: LocalesKey;
  clickToCopy: LocalesKey;
  client: LocalesKey;
  clientDebt: LocalesKey;
  clientManager: LocalesKey;
  clients: LocalesKey;
  close: LocalesKey;
  colors: LocalesKey;
  colorScheme: LocalesKey;
  colorSchemeDark: LocalesKey;
  colorSchemeLight: LocalesKey;
  comment: LocalesKey;
  company: LocalesKey;
  confirm: LocalesKey;
  contactInfo: LocalesKey;
  contactSupport: LocalesKey;
  continue: LocalesKey;
  control: LocalesKey;
  copyId: LocalesKey;
  copyLink: LocalesKey;
  copyUuid: LocalesKey;
  cost: LocalesKey;
  coefficient: LocalesKey;
  create: LocalesKey;
  createNewFilter: LocalesKey;
  criticalRequirements: LocalesKey;
  current: LocalesKey;
  date: LocalesKey;
  dateAndTimeFormat: LocalesKey;
  dateOfBirth: LocalesKey;
  day: LocalesKey;
  debt: LocalesKey;
  default: LocalesKey;
  delete_filter: LocalesKey;
  delete: LocalesKey;
  deselect: LocalesKey;
  denyAccess: LocalesKey;
  description: LocalesKey;
  descriptionOptional: LocalesKey;
  digitalOcean: LocalesKey;
  disable: LocalesKey;
  dislike: LocalesKey;
  download: LocalesKey;
  downloadAll: LocalesKey;
  downloadAllUpperCase: LocalesKey;
  downloadTaskResult: LocalesKey;
  dpi: LocalesKey;
  draft: LocalesKey;
  draftBudget: LocalesKey;
  drawings: LocalesKey;
  dueDate: LocalesKey;
  duplicate: LocalesKey;
  edit: LocalesKey;
  elasticSearch: LocalesKey;
  emailAddress: LocalesKey;
  emailChanged: LocalesKey;
  enable: LocalesKey;
  endUserLicenseAgreementAndTermsOfService: LocalesKey;
  english: LocalesKey;
  enterMessageId: LocalesKey;
  enterMessageIds: LocalesKey;
  error: LocalesKey;
  errorMessage: LocalesKey;
  eur: LocalesKey;
  expand_view: LocalesKey;
  expenses: LocalesKey;
  export: LocalesKey;
  favorite: LocalesKey;
  files: LocalesKey;
  filter: LocalesKey;
  filterFavorite: LocalesKey;
  filters: LocalesKey;
  fin: LocalesKey;
  finance: LocalesKey;
  fire: LocalesKey;
  flagged: LocalesKey;
  for: LocalesKey;
  forward: LocalesKey;
  from: LocalesKey;
  fullyPaid: LocalesKey;
  gbp: LocalesKey;
  gpt: LocalesKey;
  general: LocalesKey;
  generate: LocalesKey;
  goBack: LocalesKey;
  groupBy: LocalesKey;
  gridView: LocalesKey;
  helpCenter: LocalesKey;
  hidden: LocalesKey;
  hiddenLowerCase: LocalesKey;
  hide: LocalesKey;
  hideDimensions: LocalesKey;
  hideRuler: LocalesKey;
  hideList: LocalesKey;
  high: LocalesKey;
  home: LocalesKey;
  homepage: LocalesKey;
  images: LocalesKey;
  imageScale: LocalesKey;
  import: LocalesKey;
  in: LocalesKey;
  include: LocalesKey;
  included: LocalesKey;
  incomeStatement: LocalesKey;
  info: LocalesKey;
  invisibleForClient: LocalesKey;
  invisibleForWorker: LocalesKey;
  invisible: LocalesKey;
  invite_user: LocalesKey;
  itc: LocalesKey;
  its: LocalesKey;
  joined: LocalesKey;
  languagesSpoken: LocalesKey;
  lastMessage: LocalesKey;
  lastMonth: LocalesKey;
  lastWeek: LocalesKey;
  lastYear: LocalesKey;
  lastSixDays: LocalesKey;
  lastSixWeeks: LocalesKey;
  lastSixMonths: LocalesKey;
  lastSixYears: LocalesKey;
  latest: LocalesKey;
  laughing: LocalesKey;
  learnMore: LocalesKey;
  leave: LocalesKey;
  leaveMessage: LocalesKey;
  lessMonth: LocalesKey;
  lessTwoMonth: LocalesKey;
  lessTwoWeek: LocalesKey;
  lessWeek: LocalesKey;
  letters: {
    h: LocalesKey;
    m: LocalesKey;
  };
  license: LocalesKey;
  like: LocalesKey;
  link: LocalesKey;
  linkCopied: LocalesKey;
  listView: LocalesKey;
  loadMore: LocalesKey;
  location: LocalesKey;
  log_out: LocalesKey;
  login_page: LocalesKey;
  love: LocalesKey;
  low: LocalesKey;
  mad: LocalesKey;
  makeInvisible: LocalesKey;
  makeVisible: LocalesKey;
  makeDefault: LocalesKey;
  media: LocalesKey;
  members: LocalesKey;
  membersGenitiveLowerCase: LocalesKey;
  mergeSimilar: LocalesKey;
  model: LocalesKey;
  month: LocalesKey;
  more: LocalesKey;
  moreTwoMonth: LocalesKey;
  mostLiked: LocalesKey;
  mostUsed: LocalesKey;
  move: LocalesKey;
  myLibrary: LocalesKey;
  na: LocalesKey;
  name: LocalesKey;
  nameEn: LocalesKey;
  nda: LocalesKey;
  ndaFile: LocalesKey;
  needHelp: LocalesKey;
  next: LocalesKey;
  new: LocalesKey;
  newest: LocalesKey;
  net: LocalesKey;
  no: LocalesKey;
  noGrouping: LocalesKey;
  noResultsYet: LocalesKey;
  noSearchResults: LocalesKey;
  notAsked: LocalesKey;
  notification: LocalesKey;
  of: LocalesKey;
  ok: LocalesKey;
  on: LocalesKey;
  onboardingTour: LocalesKey;
  oneDay: LocalesKey;
  oneMonth: LocalesKey;
  oneWeek: LocalesKey;
  open_in_new_tab: LocalesKey;
  open: LocalesKey;
  openOptions: LocalesKey;
  or: LocalesKey;
  overdue: LocalesKey;
  owner: LocalesKey;
  paid: LocalesKey;
  passwordChanged: LocalesKey;
  pay: LocalesKey;
  payByCard: LocalesKey;
  payOnline: LocalesKey;
  payer: LocalesKey;
  paymentMethod: LocalesKey;
  payments: LocalesKey;
  paymentTerms: LocalesKey;
  paymentTermsAndRefundPolicy: LocalesKey;
  pendingPayments: LocalesKey;
  phoneNumber: LocalesKey;
  pinned: LocalesKey;
  policies: LocalesKey;
  postMessage: LocalesKey;
  premiumContent: LocalesKey;
  previewModel: LocalesKey;
  price: LocalesKey;
  privacy: LocalesKey;
  privacyPolicy: LocalesKey;
  proceed: LocalesKey;
  processing: LocalesKey;
  profile: LocalesKey;
  proforma: LocalesKey;
  proformaOld: LocalesKey;
  progress: LocalesKey;
  publish: LocalesKey;
  published: LocalesKey;
  px: LocalesKey;
  quantity: LocalesKey;
  receipt: LocalesKey;
  receiveNotifications: LocalesKey;
  receiver: LocalesKey;
  recentlySelected: LocalesKey;
  references: LocalesKey;
  refund: LocalesKey;
  refunds: LocalesKey;
  remove: LocalesKey;
  removeFromFavorites: LocalesKey;
  requirements: LocalesKey;
  reset_password: LocalesKey;
  reset: LocalesKey;
  resolve: LocalesKey;
  resolved: LocalesKey;
  results: LocalesKey;
  rub: LocalesKey;
  russian: LocalesKey;
  sad: LocalesKey;
  save: LocalesKey;
  saveChanges: LocalesKey;
  savedFilters: LocalesKey;
  saveFilter: LocalesKey;
  scopeOfWork: LocalesKey;
  score: LocalesKey;
  search_user_by_name: LocalesKey;
  search: LocalesKey;
  searching: LocalesKey;
  searchResults: LocalesKey;
  secondsGenitive: LocalesKey;
  select: LocalesKey;
  selectAll: LocalesKey;
  selected: LocalesKey;
  selectToAddToTheProject: LocalesKey;
  send: LocalesKey;
  sendTo: LocalesKey;
  sentTo: LocalesKey;
  sender: LocalesKey;
  sendInstructions: LocalesKey;
  sendToAllTasks: LocalesKey;
  sendToEmail: LocalesKey;
  sendViaEmail: LocalesKey;
  sent: LocalesKey;
  serviceAgreement: LocalesKey;
  serviceName: LocalesKey;
  setAllVisible: LocalesKey;
  setAllHidden: LocalesKey;
  setCustomDate: LocalesKey;
  setDueDate: LocalesKey;
  settings: LocalesKey;
  show_n_selected_items: {
    show: LocalesKey;
    hide: LocalesKey;
    selected_items: LocalesKey;
  };
  show: LocalesKey;
  showAll: LocalesKey;
  showDimensions: LocalesKey;
  showRuler: LocalesKey;
  showFavoriteOnly: LocalesKey;
  showing: LocalesKey;
  showLess: LocalesKey;
  showList: LocalesKey;
  showMore: LocalesKey;
  showMyLibrary: LocalesKey;
  showTotals: LocalesKey;
  sign_out: LocalesKey;
  size: LocalesKey;
  sketches: LocalesKey;
  skip: LocalesKey;
  skipStep: LocalesKey;
  sku: LocalesKey;
  smartContract: LocalesKey;
  sortAZ: LocalesKey;
  sortZA: LocalesKey;
  sortBy: LocalesKey;
  sortByMostLiked: LocalesKey;
  sortByMostUsed: LocalesKey;
  sortByNewest: LocalesKey;
  starred: LocalesKey;
  startDate: LocalesKey;
  state: LocalesKey;
  status: LocalesKey;
  statusColon: LocalesKey;
  start: LocalesKey;
  stopAll: LocalesKey;
  submit: LocalesKey;
  subscribed: LocalesKey;
  success: LocalesKey;
  surnameEn: LocalesKey;
  surprised: LocalesKey;
  system: LocalesKey;
  task: LocalesKey;
  tax: LocalesKey;
  tbd: LocalesKey;
  terms: LocalesKey;
  termsOfService: LocalesKey;
  terrified: LocalesKey;
  testProjectInfo: LocalesKey;
  textHere: LocalesKey;
  textures: LocalesKey;
  tfe: LocalesKey;
  thereAreNoMatchesForSelectedFilters: LocalesKey;
  threeDs: LocalesKey;
  threeDStock: LocalesKey;
  time: LocalesKey;
  timer: {
    days: LocalesKey;
    hours: LocalesKey;
    minutes: LocalesKey;
    seconds: LocalesKey;
  };
  times: LocalesKey;
  timeZone: LocalesKey;
  to: LocalesKey;
  today: LocalesKey;
  total: LocalesKey;
  totalDebt: LocalesKey;
  trackedTime: LocalesKey;
  tryAgain: LocalesKey;
  uah: LocalesKey;
  ukraine: LocalesKey;
  unblock: LocalesKey;
  underpayment: LocalesKey;
  unselectAllItems: LocalesKey;
  undefined: LocalesKey;
  units: {
    cm: LocalesKey;
    in: LocalesKey;
  };
  untagged: LocalesKey;
  unsubscribed: LocalesKey;
  update: LocalesKey;
  updated: LocalesKey;
  updateExistingFilter: LocalesKey;
  updateImageVersions: LocalesKey;
  upload: LocalesKey;
  usd: LocalesKey;
  used: LocalesKey;
  user: LocalesKey;
  vat: LocalesKey;
  view: LocalesKey;
  view3DModel: LocalesKey;
  viewAccounts: LocalesKey;
  viewAll: LocalesKey;
  viewAllMembers: LocalesKey;
  viewCR: LocalesKey;
  viewProfile: LocalesKey;
  visibility: LocalesKey;
  visibilityForClient: LocalesKey;
  visibilityForWorker: LocalesKey;
  visible: LocalesKey;
  visibleForClient: LocalesKey;
  void: LocalesKey;
  week: LocalesKey;
  welcomeToArchivizer: LocalesKey;
  whiteboard: LocalesKey;
  withdraw: LocalesKey;
  workers: LocalesKey;
  watchGuide: LocalesKey;
  workflowGuide: LocalesKey;
  writeHere: LocalesKey;
  whiteboardNotifications: LocalesKey;
  yes: LocalesKey;
  yesterday: LocalesKey;
}

export const words = keyPathMirror<WordsType, string>(
  {
    accept: null,
    accordingTo: null,
    account: null,
    accountManager: null,
    active: null,
    activity: null,
    actions: null,
    add: null,
    addItem: null,
    addMore: null,
    addReaction: null,
    addService: null,
    addToFavorites: null,
    addUser: null,
    addYourCommentOptional: null,
    adorable: null,
    all: null,
    allActivity: null,
    allAttachments: null,
    attachments: null,
    allocate: null,
    allocateFunds: null,
    allPages: null,
    allStatuses: null,
    allTime: null,
    allUsers: null,
    already_selected: null,
    amount: null,
    amountDue: null,
    and: null,
    any: null,
    anyType: null,
    applicationLanguage: null,
    apply: null,
    approve: null,
    artists: null,
    archivizerTeam: null,
    as: null,
    assignAsCreator: null,
    assignAsOwner: null,
    attach: null,
    attachImage: null,
    aud: null,
    autoAllocate: null,
    author: null,
    aws: null,
    back: null,
    backToProject: null,
    backtrace: null,
    badDebt: null,
    dataPrivacyPolicy: null,
    block: null,
    blocked: null,
    brand: null,
    brief: null,
    browse: null,
    budget: null,
    by: null,
    cancel: null,
    canceled: null,
    caution: null,
    celebrate: null,
    change: null,
    changeCurrency: null,
    changeOwner: null,
    changeUnits: null,
    clap: null,
    clear: null,
    clearAll: null,
    clearFilter: null,
    clearFilters: null,
    clearSelection: null,
    clickToCopy: null,
    client: null,
    clientDebt: null,
    clientManager: null,
    clients: null,
    close: null,
    colors: null,
    colorScheme: null,
    colorSchemeDark: null,
    colorSchemeLight: null,
    comment: null,
    company: null,
    confirm: null,
    contactInfo: null,
    contactSupport: null,
    continue: null,
    control: null,
    copyId: null,
    copyLink: null,
    copyUuid: null,
    cost: null,
    coefficient: null,
    create: null,
    createNewFilter: null,
    criticalRequirements: null,
    current: null,
    date: null,
    dateAndTimeFormat: null,
    dateOfBirth: null,
    day: null,
    debt: null,
    default: null,
    delete_filter: null,
    delete: null,
    deselect: null,
    denyAccess: null,
    description: null,
    descriptionOptional: null,
    digitalOcean: null,
    disable: null,
    dislike: null,
    download: null,
    downloadAll: null,
    downloadAllUpperCase: null,
    downloadTaskResult: null,
    dpi: null,
    draft: null,
    draftBudget: null,
    drawings: null,
    dueDate: null,
    duplicate: null,
    edit: null,
    elasticSearch: null,
    emailAddress: null,
    emailChanged: null,
    enable: null,
    endUserLicenseAgreementAndTermsOfService: null,
    english: null,
    enterMessageId: null,
    enterMessageIds: null,
    error: null,
    errorMessage: null,
    eur: null,
    expand_view: null,
    expenses: null,
    export: null,
    favorite: null,
    files: null,
    filter: null,
    filterFavorite: null,
    filters: null,
    fin: null,
    finance: null,
    fire: null,
    flagged: null,
    for: null,
    forward: null,
    from: null,
    fullyPaid: null,
    gbp: null,
    gpt: null,
    general: null,
    generate: null,
    goBack: null,
    groupBy: null,
    gridView: null,
    helpCenter: null,
    hidden: null,
    hiddenLowerCase: null,
    hide: null,
    hideDimensions: null,
    hideRuler: null,
    hideList: null,
    high: null,
    home: null,
    homepage: null,
    images: null,
    imageScale: null,
    import: null,
    in: null,
    include: null,
    included: null,
    incomeStatement: null,
    info: null,
    invisibleForClient: null,
    invisibleForWorker: null,
    invisible: null,
    invite_user: null,
    itc: null,
    its: null,
    joined: null,
    languagesSpoken: null,
    lastMessage: null,
    lastMonth: null,
    lastWeek: null,
    lastYear: null,
    lastSixDays: null,
    lastSixWeeks: null,
    lastSixMonths: null,
    lastSixYears: null,
    latest: null,
    laughing: null,
    learnMore: null,
    leave: null,
    leaveMessage: null,
    lessMonth: null,
    lessTwoMonth: null,
    lessTwoWeek: null,
    lessWeek: null,
    letters: {
      h: null,
      m: null
    },
    license: null,
    like: null,
    link: null,
    linkCopied: null,
    listView: null,
    loadMore: null,
    location: null,
    log_out: null,
    login_page: null,
    love: null,
    low: null,
    mad: null,
    makeInvisible: null,
    makeVisible: null,
    makeDefault: null,
    media: null,
    members: null,
    membersGenitiveLowerCase: null,
    mergeSimilar: null,
    model: null,
    month: null,
    more: null,
    moreTwoMonth: null,
    mostLiked: null,
    mostUsed: null,
    move: null,
    myLibrary: null,
    na: null,
    name: null,
    nameEn: null,
    nda: null,
    ndaFile: null,
    needHelp: null,
    next: null,
    new: null,
    newest: null,
    net: null,
    no: null,
    noGrouping: null,
    noResultsYet: null,
    noSearchResults: null,
    notAsked: null,
    notification: null,
    of: null,
    ok: null,
    on: null,
    onboardingTour: null,
    oneDay: null,
    oneMonth: null,
    oneWeek: null,
    open_in_new_tab: null,
    open: null,
    openOptions: null,
    or: null,
    overdue: null,
    owner: null,
    paid: null,
    passwordChanged: null,
    pay: null,
    payByCard: null,
    payOnline: null,
    payer: null,
    paymentMethod: null,
    payments: null,
    paymentTerms: null,
    paymentTermsAndRefundPolicy: null,
    pendingPayments: null,
    phoneNumber: null,
    pinned: null,
    policies: null,
    postMessage: null,
    premiumContent: null,
    previewModel: null,
    price: null,
    privacy: null,
    privacyPolicy: null,
    proceed: null,
    processing: null,
    profile: null,
    proforma: null,
    proformaOld: null,
    progress: null,
    publish: null,
    published: null,
    px: null,
    quantity: null,
    receipt: null,
    receiveNotifications: null,
    receiver: null,
    recentlySelected: null,
    references: null,
    refund: null,
    refunds: null,
    remove: null,
    removeFromFavorites: null,
    requirements: null,
    reset_password: null,
    reset: null,
    resolve: null,
    resolved: null,
    results: null,
    rub: null,
    russian: null,
    sad: null,
    save: null,
    saveChanges: null,
    savedFilters: null,
    saveFilter: null,
    scopeOfWork: null,
    score: null,
    search_user_by_name: null,
    search: null,
    searching: null,
    searchResults: null,
    secondsGenitive: null,
    select: null,
    selectAll: null,
    selected: null,
    selectToAddToTheProject: null,
    send: null,
    sendTo: null,
    sentTo: null,
    sender: null,
    sendInstructions: null,
    sendToAllTasks: null,
    sendToEmail: null,
    sendViaEmail: null,
    sent: null,
    serviceAgreement: null,
    serviceName: null,
    setAllVisible: null,
    setAllHidden: null,
    setCustomDate: null,
    setDueDate: null,
    settings: null,
    show_n_selected_items: {
      show: null,
      hide: null,
      selected_items: null
    },
    show: null,
    showAll: null,
    showDimensions: null,
    showRuler: null,
    showFavoriteOnly: null,
    showing: null,
    showLess: null,
    showList: null,
    showMore: null,
    showMyLibrary: null,
    showTotals: null,
    sign_out: null,
    size: null,
    sketches: null,
    skip: null,
    skipStep: null,
    sku: null,
    smartContract: null,
    sortAZ: null,
    sortZA: null,
    sortBy: null,
    sortByMostLiked: null,
    sortByMostUsed: null,
    sortByNewest: null,
    starred: null,
    startDate: null,
    state: null,
    status: null,
    statusColon: null,
    start: null,
    stopAll: null,
    submit: null,
    subscribed: null,
    success: null,
    surnameEn: null,
    surprised: null,
    system: null,
    task: null,
    tax: null,
    tbd: null,
    terms: null,
    termsOfService: null,
    terrified: null,
    testProjectInfo: null,
    textHere: null,
    textures: null,
    tfe: null,
    thereAreNoMatchesForSelectedFilters: null,
    threeDs: null,
    threeDStock: null,
    time: null,
    timer: {
      days: null,
      hours: null,
      minutes: null,
      seconds: null
    },
    times: null,
    timeZone: null,
    to: null,
    today: null,
    total: null,
    totalDebt: null,
    trackedTime: null,
    tryAgain: null,
    uah: null,
    ukraine: null,
    unblock: null,
    underpayment: null,
    unselectAllItems: null,
    undefined: null,
    units: {
      cm: null,
      in: null
    },
    untagged: null,
    unsubscribed: null,
    update: null,
    updated: null,
    updateExistingFilter: null,
    updateImageVersions: null,
    upload: null,
    usd: null,
    used: null,
    user: null,
    vat: null,
    view: null,
    view3DModel: null,
    viewAccounts: null,
    viewAll: null,
    viewAllMembers: null,
    viewCR: null,
    viewProfile: null,
    visibility: null,
    visibilityForClient: null,
    visibilityForWorker: null,
    visible: null,
    visibleForClient: null,
    void: null,
    week: null,
    welcomeToArchivizer: null,
    whiteboard: null,
    withdraw: null,
    workers: null,
    watchGuide: null,
    workflowGuide: null,
    writeHere: null,
    whiteboardNotifications: null,
    yes: null,
    yesterday: null
  },
  'words.'
);
