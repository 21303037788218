import { useCallback } from 'react';

import { BffApiCreateItemError } from '../../../../types';

import {
  InvoiceID,
  InvoicePaymentDate,
  InvoicePaymentMethods,
  PayInvoiceErrorCacheKeys
} from '../../invoicesTypes';

import { useBffPostQuery } from '../../../common/hooks/base/reactQuery/useBffPostQuery';
import { InvoiceBffUrl } from '../../InvoiceBffUrl';

interface PayInvoiceOptions {
  invoiceId?: InvoiceID;
  cacheKeys?: PayInvoiceErrorCacheKeys;
}

export interface PayInvoiceInput {
  invoiceId: InvoiceID;
  async?: boolean;
  invoice: {
    paymentDate?: InvoicePaymentDate;
    paymentMethod: InvoicePaymentMethods;
    confirmationId?: string;
    transactionFeeExpense?: number;
    paid?: number;
    received?: number;
  };
}

interface PayInvoiceInputData {
  invoice: {
    paymentDate?: InvoicePaymentDate;
    paymentMethod: InvoicePaymentMethods;
    confirmationId?: string;
    transactionFeeExpense?: number;
    paid?: number;
    received?: number;
  };
  actionOptions: {
    url: string;
  };
}

export type PayInvoiceError = BffApiCreateItemError;

export type PayInvoiceResponseType = unknown;

function usePayInvoice({ invoiceId, cacheKeys }: PayInvoiceOptions = {}) {
  const { postQuery, postQueryErrorMessage, postQueryLoading } =
    useBffPostQuery<
      PayInvoiceInputData,
      PayInvoiceResponseType,
      PayInvoiceError
    >({
      url: InvoiceBffUrl.pay(invoiceId),
      cacheKeys
    });

  const handlePayInvoice = useCallback<(input: PayInvoiceInput) => void>(
    (input) => {
      const { invoiceId, invoice, async } = input;
      postQuery({
        invoice,
        actionOptions: {
          url: async
            ? InvoiceBffUrl.payAsync(invoiceId)
            : InvoiceBffUrl.pay(invoiceId)
        }
      });
    },
    [postQuery]
  );

  return {
    payInvoiceLoading: postQueryLoading,
    payInvoiceErrorMessage: postQueryErrorMessage,
    payInvoice: postQuery,
    handlePayInvoice
  };
}

export default usePayInvoice;
